import { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FeedbackSummaryTableCell from './FeedbackTableCell';
import { BarChart } from '@mui/x-charts/BarChart';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Fade,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Snackbar
} from '@mui/material';
import {
  getFeedbackSummary,
  getFeedbackSummaryStatistics
} from '../../clients/rotr-client';
import { useParams, useNavigate } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import WhiteSelect from './WhiteSelect';
import Feedback from '../feedback/Feedback2';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface Column {
  id:
    | 'started_at'
    | 'started_date'
    | 'started_time'
    | 'duration'
    | 'context'
    | 'environment';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: readonly Column[] = [
  { id: 'started_date', label: 'Date', minWidth: 100 },
  { id: 'started_time', label: 'Time Started', minWidth: 100 },
  { id: 'duration', label: 'Time Taken', minWidth: 100 },
  { id: 'context', label: 'Context', minWidth: 100 },
  {
    id: 'environment',
    label: 'Environment',
    minWidth: 100,
    align: 'right',
    format: (value: number) => value.toLocaleString('en-US')
  }
];

const datePickerStyle = {
  minWidth: '50px',
  '& .MuiIconButton-root': {
    color: 'white' // Change the color of icon buttons
  },
  '& .MuiTypography-root': {
    color: 'white' // Change the color of text elements
  },
  '& .MuiInputBase-root': {
    color: 'white', // Change the color of input text
    '&.Mui-focused': {
      borderColor: 'white' // Change to your desired active/focused border color
    },
    '&.Mui-focused:hover': {
      borderColor: 'gray' // Change to your desired active/focused border color when hovered
    }
  },
  '& .MuiInput-underline:before': {
    borderBottomColor: 'white' // Change the color of input underline before focus
  },
  '& .MuiInput-underline:hover:before': {
    borderBottomColor: 'white' // Change the color of input underline on hover
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white' // Change the color of input underline after focus
  },
  '& .MuiInputLabel-root': {
    color: 'white' // Set color for the label
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'white' // Set color for the border
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'gray' // Set color for the border on hover
  },
  '& fieldset': {
    borderColor: 'white' // Set the border color
  },
  '&::before': {
    borderColor: 'white' // Set underline color to white
  },
  '&::after': {
    borderBottomColor: 'white', // Set underline color when focused to white
    borderColor: 'white' // Set the triangle icon color
  },
  '&:not(.Mui-disabled):hover::before': {
    borderColor: 'white'
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)' // border colour override
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)' // colour after selecting a value
  },
  '.MuiSvgIcon-root ': {
    fill: 'white !important' // the triangle dropdown icon colour
  },
  '.MuiPickersToolbar-root': {
    backgroundColor: '#333'
  },
  '.MuiDateCalendar-root': {
    backgroundColor: '#333'
  }
};

const selectStyle = {
  color: 'white', // Set Select text color to white
  // "&:before": {
  //   borderBottomColor: "white", // Set underline color to white
  // },
  // "&:after": {
  //   borderBottomColor: "white", // Set underline color when focused to white
  //   borderColor: "white", // Set triangle icon color to white
  // },
  // icon: {
  //   fill: "white", // Set icon color to white
  // },
  // root: {
  //   color: "white", // Set root color to white
  // },
  '& fieldset': {
    borderColor: 'white' // Set the border color
  },
  '&::before': {
    borderColor: 'white' // Set underline color to white
  },
  '&::after': {
    borderBottomColor: 'white', // Set underline color when focused to white
    borderColor: 'white' // Set the triangle icon color
  },
  '&:not(.Mui-disabled):hover::before': {
    borderColor: 'white'
  },
  '.MuiOutlinedInput-notchedOutline': {
    //   borderColor: "rgba(228, 219, 233, 0.25)", // border colour override
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)' // colour after selecting a value
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgba(228, 219, 233, 0.25)' // colour while selecting from the dropdown
  },
  '.MuiSvgIcon-root ': {
    fill: 'white !important' // the triangle dropdown icon colour
  }
};

interface FeedbackViewModel {
  id: string;
  started_at: string;
  started_date: string;
  started_time: string;
  duration: string;
  ended_at: string;
  context: string;
  environment: number;
}

interface FeedbackSummaryProps {
  userID?: string;
}
const newTheme = (theme: any) =>
  createTheme({
    ...theme
    // palette: {
    //   background: {
    //     default: '#333'
    //   }
    // },
    // components: {
    //   MuiPickersToolbar: {
    //     styleOverrides: {
    //       root: {
    //         color: 'white',
    //         borderRadius: '2px',
    //         borderWidth: '1px',
    //         borderColor: 'black',
    //         border: '1px solid',
    //         backgroundColor: '#333'
    //       }
    //     }
    //   }
    // }
  });

export default function FeedbackSummary({ userID }: FeedbackSummaryProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataRows, setDataRows] = useState<FeedbackViewModel[]>([]);
  const [numRows, setNumRows] = useState(0);
  const [contextFilter, setContextFilter] = useState('all');
  const [environmentFilter, setEnvironmentFilter] = useState('all');
  const [startDateFilter, setStartDateFilter] = useState<Date | null>(null);
  const [endDateFilter, setEndDateFilter] = useState<Date | null>(null);
  const [feedbackId, setFeedbackId] = useState<string | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [openError, setOpenError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCloseError = () => setOpenError(false);

  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setFeedbackId(id);
      setOpen(true);
      // Remove the id parameter from the URL
      navigate('.', { replace: true });
    }
  }, [id, navigate]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const fetchFeedbackSummary = async () => {
    setLoadingData(true);
    getFeedbackSummary({
      pageSize: rowsPerPage,
      page: page + 1, // backend is 1-indexed
      context: contextFilter === 'all' ? undefined : contextFilter,
      environment: environmentFilter === 'all' ? undefined : environmentFilter,
      started_at:
        startDateFilter === null ? undefined : startDateFilter.toISOString(),
      ended_at:
        endDateFilter === null ? undefined : endDateFilter.toISOString(),
      user_id: userID
    })
      .then((response) => {
        // the data's started_at is UTC, convert it to local time before setDataRows
        if (response.data.length === 0) {
          setDataRows([]);
          setNumRows(0);
        }
        response.data.forEach(
          (row: {
            duration: number | string | Date;
            ended_at: string | Date;
            started_at: string | Date;
            started_date: string;
            started_time: string;
          }) => {
            // add a duration to the row using ended_at - started_at and formatted to hh:mm:ss
            row.duration = Math.floor(
              (new Date(row.ended_at).getTime() -
                new Date(row.started_at).getTime()) /
                1000
            );
            row.duration = new Date(row.duration * 1000)
              .toISOString()
              .substr(11, 8);
            row.ended_at = new Date(row.ended_at).toLocaleString();

            const startedAt = new Date(row.started_at);
            const formattedDate = startedAt.toLocaleDateString(undefined, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              hour12: false
            });
            const formattedTime = startedAt.toLocaleTimeString(undefined, {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit',
              hour12: false
            });
            row.started_at = `${formattedDate} ${formattedTime}`;
            row.started_date = formattedDate;
            row.started_time = formattedTime;
            setDataRows(response.data);
            setNumRows(response.total_hits);
          }
        );
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenError(true);
      })
      .finally(() => {
        setLoadingData(false);
      });
  };

  // let weeksLabels: string[] = [];
  const [chartLabels, setChartLabels] = useState<Date[]>([]);
  const [chartData, setChartData] = useState<any[]>([0]);
  const fetchFeedbackSummaryStatistics = async () => {
    if (userID === undefined) {
      return;
    }
    getFeedbackSummaryStatistics({
      context: contextFilter === 'all' ? undefined : contextFilter,
      environment: environmentFilter === 'all' ? undefined : environmentFilter,
      started_at:
        startDateFilter === null ? undefined : startDateFilter.toISOString(),
      ended_at:
        endDateFilter === null ? undefined : endDateFilter.toISOString(),
      user_id: userID
    })
      .then((response) => {
        setChartLabels(
          response.map(
            // convert week_start to a Date object
            (week: { week_start: string }) => new Date(week.week_start)
          )
        );
        const responseCounts = response.map((x: any) => x.response_count);
        setChartData(
          response.map(
            // convert week_start to a Date object
            (week: { response_count: number }) => week.response_count
          )
        );
      })
      .catch((err) => {
        setErrMsg(err.message);
        setOpenError(true);
      });
  };

  //   useEffect call getFeedbackSummary
  useEffect(() => {
    fetchFeedbackSummary();
  }, [
    rowsPerPage,
    page,
    contextFilter,
    environmentFilter,
    startDateFilter,
    endDateFilter
  ]);

  useEffect(() => {
    fetchFeedbackSummaryStatistics();
  }, [userID, contextFilter, environmentFilter]);

  // value formatter than converts date to DD/MM string
  const valueFormatter = (date: Date) =>
    date.toLocaleDateString('en-AU', {
      month: '2-digit',
      day: '2-digit'
    });

  return (
    <ThemeProvider theme={newTheme}>
      <div className={userID ? 'modal-content' : 'main-content'}>
        {/* <AggStats></AggStats> */}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loadingData}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
        {userID ? (
          <Box paddingLeft={3}>
            <BarChart
              xAxis={[
                {
                  data: chartLabels,
                  scaleType: 'band',
                  valueFormatter: valueFormatter
                }
              ]}
              series={[
                {
                  data: chartData,
                  stack: 'A',
                  label: 'Scenario Responses'
                }
              ]}
              slotProps={{
                legend: {
                  labelStyle: {
                    fontSize: 14,
                    fill: 'white'
                  }
                }
              }}
              // width={600}
              height={350}
              // white text
              sx={{
                // backgroundColor: "green", //debug colour
                //change left yAxis label styles
                '& .MuiChartsAxis-left .MuiChartsAxis-tickLabel': {
                  strokeWidth: '0.4',
                  fill: '#ffffff'
                },
                // change all labels fontFamily shown on both xAxis and yAxis
                '& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel': {
                  fontFamily: 'Roboto'
                },
                // change bottom label styles
                '& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel': {
                  strokeWidth: '0.5',
                  fill: '#FFFFFF'
                },
                // bottomAxis Line Styles
                '& .MuiChartsAxis-bottom .MuiChartsAxis-line': {
                  stroke: '#FFFFF0', // for some reason if this is full white then the ticks become black
                  strokeWidth: 0.4
                },
                // leftAxis Line Styles
                '& .MuiChartsAxis-left .MuiChartsAxis-line': {
                  stroke: '#FFFFFF',
                  strokeWidth: 0.4
                },
                // change grid line styles
                '& .MuiChartsGrid-line': {
                  stroke: '#FFFFFF',
                  strokeWidth: 0.4
                },
                // white ticks
                '& .MuiChartsAxis-tick': {
                  stroke: '#FFFFFF'
                }
              }}
            />
          </Box>
        ) : null}
        <Box padding={2}>
          {/* filter section that allows users to choose start and end dates, context and environment */}
          {/* horizontal oriented box */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: "space-evenly",
              alignItems: 'center',
              marginBottom: '20px',
              flexWrap: 'wrap'
            }}
            gap={2}
          >
            Filters
            {/* date picker */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='Start date'
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  },
                  layout: {
                    sx: {
                      backgroundColor: '#333'
                    }
                  }
                }}
                sx={datePickerStyle}
                value={startDateFilter}
                format='DD/MM/YYYY'
                onChange={(newValue: any) => {
                  try {
                    // in case user manually enters invalid date
                    newValue.toISOString();
                    setStartDateFilter(newValue);
                  } catch (error) {
                    setStartDateFilter(null);
                  }
                }}
              />
              -
              <DatePicker
                label='End date'
                slotProps={{
                  actionBar: {
                    actions: ['clear']
                  },
                  layout: {
                    sx: {
                      backgroundColor: '#333'
                    }
                  }
                }}
                sx={datePickerStyle}
                format='DD/MM/YYYY'
                value={endDateFilter}
                onChange={(newValue: any) => {
                  try {
                    // in case user manually enters invalid date
                    newValue.toISOString();
                    setEndDateFilter(newValue);
                  } catch (error) {
                    setEndDateFilter(null);
                  }
                }}
              />
            </LocalizationProvider>
            <FormControl sx={{ width: '100px' }}>
              <InputLabel
                id='demo-simple-select-label'
                sx={{
                  color: 'white', // Set label text color to white
                  '&.MuiInputLabel-shrink': {
                    color: 'white' // Set active label color
                  }
                }}
              >
                Context
              </InputLabel>
              <Select
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: '#333'
                      }
                    }
                  }
                }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={contextFilter}
                label='Context'
                onChange={(e) => {
                  setContextFilter(e.target.value as string);
                }}
                sx={selectStyle}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'NC'}>NC</MenuItem>
                <MenuItem value={'OW'}>OW</MenuItem>
                <MenuItem value={'TSS'}>TSS</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: '100px' }}>
              <InputLabel
                id='demo-simple-select-label'
                sx={{
                  color: 'white', // Set label text color to white
                  '&.MuiInputLabel-shrink': {
                    color: 'white' // Set active label color
                  }
                }}
              >
                Environment
              </InputLabel>
              <WhiteSelect
                inputProps={{
                  MenuProps: {
                    MenuListProps: {
                      sx: {
                        backgroundColor: '#333'
                      }
                    }
                  }
                }}
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={environmentFilter}
                label='Environment'
                onChange={(e) => {
                  setEnvironmentFilter(e.target.value as string);
                }}
                sx={selectStyle}
              >
                <MenuItem value={'all'}>All</MenuItem>
                <MenuItem value={'day'}>Day</MenuItem>
                <MenuItem value={'night'}>Night</MenuItem>
                <MenuItem value={'resVis'}>Res Vis</MenuItem>
              </WhiteSelect>
            </FormControl>
          </Box>

          <Paper
            sx={{ width: '100%', overflow: 'hidden', backgroundColor: '#333' }}
          >
            <TableContainer sx={{ backgroundColor: '#161A1Dff' }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead
                  sx={{
                    '& .MuiTableCell-head': {
                      color: 'white',
                      backgroundColor: '#5243AAff'
                    }
                  }}
                >
                  <TableRow>
                    {columns.map((column) => (
                      <FeedbackSummaryTableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        <b>{column.label}</b>
                      </FeedbackSummaryTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataRows.map((row) => {
                    return (
                      <TableRow
                        hover
                        role='checkbox'
                        tabIndex={-1}
                        key={row.id}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <FeedbackSummaryTableCell
                              key={column.id}
                              align={column.align}
                            >
                              <Button
                                size='medium'
                                sx={{
                                  color: 'white',
                                  fontSize: '14px',
                                  minHeight: 0,
                                  minWidth: 0,
                                  padding: 0
                                }}
                                onClick={() => {
                                  // navigate(`/feedback/${row.id}`);
                                  setFeedbackId(row.id);
                                  setOpen(true);
                                }}
                              >
                                {column.format && typeof value === 'number'
                                  ? column.format(value)
                                  : value}
                              </Button>
                            </FeedbackSummaryTableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component='div'
              count={numRows}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                sx: {
                  '.MuiSvgIcon-root ': {
                    fill: 'white !important' // the triangle dropdown icon colour
                  }
                }
                //   native: true,
              }}
              // all text colour to be white
              sx={{
                '.MuiTablePagination-displayedRows': {
                  color: 'white'
                },
                '.MuiTablePagination-selectLabel': {
                  color: 'white'
                },
                '& .MuiTablePagination-select': {
                  color: 'white' // Customize text color
                },
                '& .MuiTablePagination-actions': {
                  color: 'white' // Customize text color
                }
              }}
            />
          </Paper>
        </Box>
        <Modal
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          open={open}
          onClose={handleClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}
        >
          <Paper
            style={{
              width: '95%',
              maxHeight: '90%', // 90% height
              overflowY: 'auto',
              color: 'white',
              backgroundColor: '#414141',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <IconButton
              size='large'
              sx={{
                color: 'white',
                position: 'absolute',
                top: '5%',
                left: '1%'
              }}
              onClick={handleClose}
            >
              <CancelTwoToneIcon fontSize='large' />
            </IconButton>
            <Feedback inputId={feedbackId} />
          </Paper>
        </Modal>
        <Snackbar
          open={openError}
          autoHideDuration={6000}
          onClose={handleCloseError}
        >
          <Alert
            onClose={handleCloseError}
            severity='error'
            sx={{ width: '100%' }}
          >
            {errMsg}
          </Alert>
        </Snackbar>
      </div>
    </ThemeProvider>
  );
}
