import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  Paper,
  Alert
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { UUID } from 'crypto';
import ScoreBoard from './ScoreBoard';
import { getStepAttempt } from '../../../clients/rotr-client';
import TimerIcon from '@mui/icons-material/Timer';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import TaskCompleteBanner from '../../tasks/TaskCompleteBanner';
import NavCompact from '../../header/NavCompact';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.custom.page,
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '0'
}));

interface TaskCompleteBannerProps {
  taskComplete: boolean | null;
  taskTitle?: string | null;
  taskType?: 'scenario' | 'recite' | 'quiz' | null;
  nextTask?: {
    task_url?: string;
    task_type?: 'scenario' | 'recite' | 'quiz';
  };
}

const GameOver: React.FC = () => {
  const { attemptId } = useParams<{ attemptId: UUID | undefined }>();
  const [stepId, setStepId] = useState<UUID | undefined>(undefined);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [score, setScore] = useState(0);
  const [time, setTime] = useState(0);
  const [starAchieved, setStarAchieved] = useState<string>('none');
  const [showAchievement, setShowAchievement] = useState(false);
  const [maxScore, setMaxScore] = useState(0);
  const [isQuiz, setIsQuiz] = useState(false);
  const [taskComplete, setTaskComplete] = useState<boolean | null>(null);
  const [taskTitle, setTaskTitle] = useState<string | null>(null);
  const [taskType, setTaskType] = useState<string | null>(null);
  const [newTask, setNewTask] = useState<{
    task_url?: string;
    task_type?: 'scenario' | 'recite' | 'quiz';
  } | null>(null);

  useEffect(() => {
    if (attemptId) {
      getStepAttempt(attemptId)
        .then((attempt) => {
          if (attempt) {
            setScore(attempt.score);
            setTime(Math.round(attempt.time_seconds * 1000) / 1000);
            setStepId(attempt.step_id);
            setMaxScore(attempt.max_score);
            setIsQuiz(attempt.isQuiz);
            setTaskComplete(attempt.taskComplete);
            setNewTask(attempt.nextTask);
            setTaskTitle(attempt.taskTitle);
            setTaskType(attempt.taskType);
          }
          checkForStartAchievement(attempt);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching step attempt:', error);
          setError('Failed to fetch step attempt. Please try again.');
          setLoading(false);
        });
    }
  }, [attemptId]);

  const checkForStartAchievement = (attempt: any) => {
    const percentage = attempt.score / attempt.max_score;
    if (percentage >= 0.9) {
      setStarAchieved('gold');
      setShowAchievement(true);
    } else if (percentage >= 0.8) {
      setStarAchieved('silver');
      setShowAchievement(true);
    } else if (percentage >= 0.7) {
      setStarAchieved('bronze');
      setShowAchievement(true);
    } else {
      setStarAchieved('none');
      setShowAchievement(false);
    }
  };

  if (loading) {
    return (
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 'calc(100vh - 50px)',
          backgroundColor: 'rgb(22, 26, 29)',
          padding: '0',
          overflow: 'hidden',
          borderRadius: '0'
        }}
      >
        <CircularProgress />
      </Paper>
    );
  }

  return (
    <StyledPaper>
      <Container
        maxWidth='md'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start'
        }}
      >
        <Box sx={{ mb: 2 }}>
          <NavCompact />
        </Box>

        <TaskCompleteBanner
          payload={{
            taskComplete: taskComplete,
            nextTask: newTask ?? undefined,
            taskTitle: taskTitle ?? undefined,
            taskType: taskType ?? undefined
          }}
        />
        <Box
          style={{
            width: '100%',
            maxWidth: '800px',
            padding: '24px',
            color: 'whitesmoke',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            backgroundColor: '#1d2125',
            minHeight: '100%'
          }}
        >
          {error && <Alert severity='error'>{error}</Alert>}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '20px'
            }}
          >
            <Typography variant='h3' marginBottom={'1rem'}>
              🏁 - You Finished - 🏁
            </Typography>
            {showAchievement && isQuiz && (
              <Box
                style={{
                  marginTop: '2rem',
                  marginBottom: '2rem',
                  padding: '2rem',
                  backgroundColor: '#161A1Dff',
                  color: 'whitesmoke',
                  width: '100%',
                  maxWidth: '600px',
                  minWidth: '200px',
                  border: '1px solid whitesmoke'
                }}
              >
                <div className='icon-container'>
                  <StarTwoToneIcon
                    className='spinStar'
                    style={{
                      fontSize: '5rem',
                      color:
                        starAchieved === 'gold'
                          ? '#FCE56F'
                          : starAchieved === 'silver'
                          ? '#D9D9D9'
                          : '#E09663',
                      animation: 'spinStar 3s infinite linear'
                    }}
                  />
                </div>
                <Typography variant='h5' style={{ marginBottom: '1rem' }}>
                  {starAchieved === 'gold'
                    ? 'Gold'
                    : starAchieved === 'silver'
                    ? 'Silver'
                    : 'Bronze'}{' '}
                  Achievement
                </Typography>
                {starAchieved === 'gold' ? (
                  <Typography variant='h6' style={{ marginBottom: '1rem' }}>
                    Gold star achievement unlocked. Great job!
                  </Typography>
                ) : starAchieved === 'silver' ? (
                  <Typography variant='h6' style={{ marginBottom: '1rem' }}>
                    Silver star achievement unlocked. Great job!
                  </Typography>
                ) : (
                  <Typography variant='h6' style={{ marginBottom: '1rem' }}>
                    Bronze star achievement unlocked. Good job!
                  </Typography>
                )}
              </Box>
            )}

            <Typography variant='body1' style={{ marginBottom: '1.2rem' }}>
              Score:{' '}
              <span
                style={{
                  fontSize: '1.3rem',
                  fontWeight: 'bold',
                  lineHeight: 'inherit'
                }}
              >
                {score}/{maxScore}{' '}
              </span>
              <i className={`fa-solid fa-circle-bolt`}></i>
            </Typography>
            <Typography
              variant='body1'
              style={{
                marginBottom: '1.2rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <span style={{ marginRight: '0.3rem' }}>Time:</span>

              <span
                style={{
                  fontSize: '1.3rem',
                  fontWeight: 'bold',
                  lineHeight: 'inherit',
                  marginRight: '0.5rem'
                }}
              >
                {`${Math.floor(Math.round(time) / 60)
                  .toString()
                  .padStart(2, '0')}m ${(Math.round(time) % 60)
                  .toString()
                  .padStart(2, '0')}s `}
              </span>
              <TimerIcon style={{ fontSize: '1.3rem' }} />
            </Typography>
            <div
              style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
            >
              <Link to={`/games/shake-down/steps/${stepId}/intro`}>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ height: '40px', marginBottom: '1rem' }}
                  startIcon={<RestartAltIcon />}
                >
                  Retry
                </Button>
              </Link>
              <Link to={`/games/shake-down`}>
                <Button
                  variant='contained'
                  color='primary'
                  style={{ height: '40px' }}
                  startIcon={<ArticleOutlinedIcon />}
                >
                  All Quizzes
                </Button>
              </Link>
            </div>
          </div>

          {isQuiz && (
            <Box
              style={{
                marginTop: '2rem',
                padding: '2rem',
                backgroundColor: '#161A1Dff',
                color: 'whitesmoke',
                width: '100%',
                maxWidth: '600px',
                minWidth: '200px'
              }}
            >
              <ScoreBoard stepId={stepId} />
            </Box>
          )}
        </Box>
      </Container>
    </StyledPaper>
  );
};

export default GameOver;
