import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import NextQuestion from './NextQuestion';
import { postAnswer } from '../../../clients/rotr-client';
import { UUID } from 'crypto';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import RuleDisplay from './RuleDisplay';

interface ResponseAnswer {
  id: string;
  score: number;
  correct_answers: string[];
  is_correct: boolean;
}

interface Option {
  optionId: string;
  optionContent: string;
}

interface Question {
  attemptID: UUID;
  content: string;
  instruction: string;
  mediaURL: string;
  options: Option[];
  questionID: UUID;
  questionNumber: number;
  rules: string[];
  token: string;
  totalQuestions: number;
  type: string;
}

interface RenderClozePassageQuestionProps {
  attemptId: UUID;
  questionId: UUID;
  currentQuestion: Question;
  currentQuestionIndex: number;
  submitted: boolean;
  setSubmitted: (value: boolean) => void;
  handleNextQuestion: () => void;
  updateScore: (value: number) => void;
  setTimerRunning: (value: boolean) => void;
}

const RenderClozePassageQuestion: React.FC<RenderClozePassageQuestionProps> = ({
  attemptId,
  questionId,
  currentQuestion,
  currentQuestionIndex,
  submitted,
  setSubmitted,
  handleNextQuestion,
  updateScore,
  setTimerRunning
}) => {
  const [inputs, setInputs] = useState<string[]>([]);
  const [optionIds, setOptionIds] = useState<string[]>([]);
  const [correctOptions, setCorrectOptions] = useState<ResponseAnswer[]>([]);
  const [isCorrect, setIsCorrect] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    // Reset states for new question
    setSubmitted(false);
    setCorrectOptions([]);
    setIsCorrect(false);
    setIsChecked(false);
    setShowNextButton(false);
    setTimerRunning(false);

    const blanksCount = (currentQuestion.content.match(/{[^}]*}/g) || [])
      .length;
    setInputs(new Array(blanksCount).fill(''));
    const extractedIds =
      currentQuestion.content
        .match(/{([^}]*)}/g)
        ?.map((id) => id.slice(1, -1)) || [];
    setOptionIds(extractedIds);

    setTimerRunning(true);
  }, [currentQuestion, setSubmitted, setTimerRunning]);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newInputs = [...inputs];
    newInputs[index] = e.target.value;
    setInputs(newInputs);
  };

  const handleSubmit = async () => {
    if (submitted) return; // Prevent duplicate submissions
    setSubmitted(true);

    const answers = inputs.map((input, index) => ({
      id: optionIds[index],
      answer: input
    }));
    if (answers.some((answer) => !answer.id)) {
      console.error('Error: Some answers are missing option IDs.');
      return;
    }

    try {
      setTimerRunning(false);
      const APIResponse = await postAnswer(attemptId, questionId, answers);
      setCorrectOptions(APIResponse.responseAnswers);

      // Check if all responses are correct
      const allCorrect = APIResponse.responseAnswers.every(
        (option: ResponseAnswer) => option.is_correct
      );
      setIsCorrect(allCorrect);

      // Delay showing the result until isChecked is set
      setTimeout(() => {
        setIsChecked(true);
      }, 0);

      setShowNextButton(true);
      updateScore(APIResponse.score);
    } catch (error) {
      console.error('Error submitting answer:', error);
      setSubmitted(false);
    }
  };

  const renderCorrectAnswers = (content: string) => {
    const parts = content.split(/({[^}]*})/g);
    let blankIndex = 0;
    return (
      <div>
        {parts.map((part, index) => {
          if (part.startsWith('{') && part.endsWith('}')) {
            const answerIndex = blankIndex++;
            const correctOption = correctOptions.find(
              (option) => option.id === optionIds[answerIndex]
            );
            return (
              <ol key={index}>
                {correctOption?.correct_answers.map((answer, idx) => (
                  <li key={idx}>
                    <strong>{answer}</strong>
                  </li>
                ))}
              </ol>
            );
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </div>
    );
  };

  const processContent = (content: string, useCorrectAnswers = false) => {
    const parts = content.split(/({[^}]*})/g);
    let blankIndex = 0;
    const contentParts = parts.map((part, index) => {
      if (part.startsWith('{') && part.endsWith('}')) {
        const answerIndex = blankIndex++;
        const correctOption = correctOptions.find(
          (option) => option.id === optionIds[answerIndex]
        );
        const backgroundColor =
          isChecked && correctOption
            ? correctOption.is_correct
              ? 'green'
              : 'red'
            : 'transparent';
        const displayValue = useCorrectAnswers
          ? correctOption?.correct_answers[0] || ''
          : inputs[answerIndex] || '';
        return (
          <span key={index}>
            <ResizableTextField
              value={displayValue}
              onChange={(e) => handleInputChange(e, answerIndex)}
              submitted={submitted}
              backgroundColor={backgroundColor}
              useCorrectAnswers={useCorrectAnswers}
            />
          </span>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });

    return { contentParts };
  };

  const { contentParts } = processContent(currentQuestion.content || '');

  return (
    <Box
      sx={{
        width: '100%',
        margin: '4px 0',
        padding: '0 12px',
        textAlign: 'left',
        color: 'white',
        minHeight: '200px',
        lineHeight: '2.5'
      }}
    >
      <Box
        sx={{
          backgroundColor: isChecked ? (isCorrect ? 'green' : 'red') : 'purple',
          padding: '20px',
          borderRadius: '10px',
          marginBottom: '10px'
        }}
      >
        <Typography variant='h6' sx={{ fontSize: '1.0rem' }}>
          <strong>{currentQuestion.instruction}</strong>
          <RuleDisplay
            inputRules={currentQuestion.rules}
            showRuleLink={false}
          />
          {isChecked && isCorrect && (
            <Typography
              variant='h4'
              sx={{ color: 'white', marginTop: '10px', fontWeight: 'bold' }}
            >
              Correct! <CheckCircleTwoToneIcon />
            </Typography>
          )}
        </Typography>
      </Box>
      <Box
        className='content'
        sx={{
          marginTop: '20px',
          color: 'white'
        }}
      >
        {contentParts}
      </Box>
      {!submitted && (
        <Box
          display='flex'
          justifyContent='center'
          marginTop='20px'
          marginBottom='20px'
        >
          <Button
            variant='contained'
            sx={{
              backgroundColor: 'purple',
              '&:hover': {
                backgroundColor: '#800080'
              }
            }}
            onClick={handleSubmit}
          >
            SUBMIT
          </Button>
        </Box>
      )}
      {submitted && (
        <Box
          sx={{
            marginTop: '20px',
            backgroundColor: 'transparent',
            border: '1px solid white',
            padding: '10px',
            borderRadius: '5px',
            textAlign: 'left',
            marginBottom: '100px'
          }}
        >
          <Typography variant='body2' sx={{ color: 'white' }} component='div'>
            Correct Answers:
            {renderCorrectAnswers(currentQuestion.content || '')}
          </Typography>
        </Box>
      )}
      {showNextButton && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
            marginTop: '8px',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <NextQuestion onClick={handleNextQuestion} />
        </Box>
      )}
    </Box>
  );
};

interface ResizableTextFieldProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  submitted: boolean;
  backgroundColor: string;
  useCorrectAnswers: boolean;
}

const ResizableTextField: React.FC<ResizableTextFieldProps> = ({
  value,
  onChange,
  submitted,
  backgroundColor,
  useCorrectAnswers
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = 'auto';
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
    }
  }, [value]);

  return (
    <Box
      sx={{
        display: 'inline-block',
        maxWidth: '100%',
        overflowX: 'auto',
        marginBottom: '-17px'
      }}
    >
      <TextField
        inputRef={inputRef}
        variant='outlined'
        size='small'
        value={value}
        onChange={onChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: '0',
            '& fieldset': {
              borderColor: '#1E90FF'
            },
            '&:hover fieldset': {
              borderColor: '#1E90FF'
            },
            '&.Mui-focused fieldset': {
              borderColor: '#1E90FF'
            }
          },
          '& .MuiInputBase-input': {
            color: 'white',
            padding: '8px 10px',
            backgroundColor: submitted ? backgroundColor : 'transparent',
            '&:focus': {
              backgroundColor: 'transparent'
            },
            whiteSpace: 'nowrap',
            overflowX: 'auto'
          }
        }}
        inputProps={{
          spellCheck: 'true',
          style: {
            height: '100%',
            fontSize: '1rem'
          },
          autoComplete: 'off'
        }}
        InputProps={{
          readOnly: useCorrectAnswers
        }}
      />
    </Box>
  );
};

export default RenderClozePassageQuestion;
